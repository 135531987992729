<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Mural de Avisos</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form ref="form">
      <v-row
        justify="start"
        no-gutters
        class="pa-2"
      >
        <v-col
          class="d-flex"
          lg="2"
          md="2"
        >
          <v-checkbox
            v-model="noticeBoard.active"
            label="Ativo?"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="pa-2 mt-0"
        justify="center"
        align="center"
      >
        <v-col
          class="d-flex pr-5"
          lg="5"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="noticeBoard.title"
            outlined
            dense
            label="Título"
            :rules="$rules.required"
            required
            :items="noticeBoard"
            item-text="title"
            item-value="id"
          />
        </v-col>
        <v-col
          class="d-flex pr-5"
          lg="5"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="noticeBoard.subTitle"
            outlined
            dense
            label="Subtítulo"
            :rules="$rules.required"
            required
            :items="noticeBoard"
            item-text="subTitle"
            item-value="id"
          />
        </v-col>
        <v-col
          class="d-flex pr-5"
          lg="10"
          md="12"
          sm="12"
        >
          <v-select
            v-model="noticeBoard.colCa"
            outlined
            dense
            multiple
            :items="collaboratorCategory"
            item-text="title"
            return-object
            label="Categoria dos colaboradores"
            class="form-control"
          />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="pa-2 mt-0"
        justify="center"
        align="center"
      >
        <v-col
          class="d-flex pr-5"
          lg="4"
          md="12"
          sm="12"
        >
          <date-picker
            :title="initialDateTitle"
            :date-parent="formattedDate(noticeBoard.initialDate)"
            @update-date="updateInitialDate"
          />
          <date-picker
            :title="finalDateTitle"
            :date-parent="formattedDate(noticeBoard.finalDate)"
            @update-date="updateFinalDate"
          />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="pa-2 mt-0"
        justify="center"
        align="center"
      >
        <v-col
          class="d-flex pr-5"
          sm="6"
        >
          <kosmo-editor
            v-model="noticeBoard.text"
            label="Texto*"
            :rules="$rules.required"
            required
            item-text="text"
            item-value="id"
          />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="pa-2 mt-0"
        justify="center"
        align="center"
      >
        <v-col
          class="pr-5"
          cols="10"
        >
          <v-file-input
            v-model="imagesUpload"
            outlined
            required
            :images="imagesPreview"
            label="Clique para adicionar imagens (mínimo 1024x768)."
            prepend-icon="mdi-camera"
            accept="image/jpeg, image/png"
            @change="onFilesSelected"
          />
        </v-col>
      </v-row>

      <v-dialog
        v-model="dialogCropImage"
        persistent
      >
        <v-card>
          <div>
            <v-card-title class="text-h5">
              Recorte da imagem para tamanho 1024x768
            </v-card-title>
          </div>
          <div>
            <v-card-text>
              Para melhor visualização da imagem no Informa Site, recomendamos
              utilizar o tamanho da imagem em 1024x768. <br>
              Utilize o "scroll" do mouse para ajustar a imagem.
              <br>
              Clique e segure na imagem para arrastar e posicionar.
            </v-card-text>
          </div>
          <div style="padding-left: 600px">
            <resize-image
              ref="image"
              :image-param="imagesPreview"
            />
          </div>
          <v-card-actions>
            <v-btn
              color="red darken-1"
              text
              @click="cancelCropImage()"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="cropImageOnConfirm"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row
        class="mt-5"
        justify="center"
        no-gutters
      >
        <v-col
          class="pr-5"
          cols="12"
          sm="10"
        >
          <gallery
            :images="imagesPreview"
            :rules="$rules.required"
            :disable-delete-button="disableDeleteButton"
            @image-deleted="onDeleteImage"
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        align="center"
        no-gutters
        class="pa-2 mt-6"
      >
        <v-col
          class="d-flex pr-5"
          lg="10"
          md="12"
          sm="12"
        >
          <v-file-input
            v-model="attcUpload"
            label="Clique para adicionar novos arquivos"
            multiple
            outlined
            show-size
            counter
            accept="application/pdf,application/vnd.ms-excel"
          >
            <template #selection="{ index, text }">
              <v-chip
                v-if="index < 4"
                class="white accent-4"
                label
              >
                {{ text }}
              </v-chip>
              <span
                v-else-if="index === 4"
                class="text-overline grey--text text--darken-3 mx-2"
              >
                +{{ attcUpload.length - 4 }} arquivo(s)
              </span>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
    </v-form>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="loading === true"
          color="primary"
          loading
          @click="submit"
        />
        <v-btn
          v-else
          color="primary"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask';
import dayjs from 'dayjs';
import sanitize from 'sanitize-filename';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import NoticeBoard from '../../../services/notice-board.service';
import { createNoticeBoardPhoto } from '../../../services/notice-board-photo.service';
import CollaboratorCategoryService from '../../../services/collaborator-category.service';
import { image } from '../../../services/upload-service';
import ResizeImage from '../../../components/shared/ResizeImage.vue';
import imageFormater from '../../../utils/convert-image-to-file';

// Support dayjs from Firefox
dayjs.extend(customParseFormat);

export default {
  name: 'NoticeBoard',
  components: {
    'date-picker': () => import('../../../components/shared/DatePicker'),
    ResizeImage,
  },
  directives: { mask },
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      noticeBoard: {
        active: false,
        title: '',
        subTitle: '',
        text: '',
        link: '',
        initialDate: '',
        finalDate: '',
        colCa: [],
        attc: [],
      },
      attcUpload: [],
      disableDeleteButton: true,
      dialogCropImage: false,
      collaborator: {},
      imagesPreview: [],
      imagesUpload: null,
      collaboratorCategory: [],
      loading: false,
      initialDateTitle: 'Data Inicio',
      finalDateTitle: 'Data Fim',
    };
  },
  async created() {
    this.loadCollaboratorCategories();
    if (this.populateWith.id) {
      this.noticeBoard = this.populateWith;
      const actualCollabCategory = this.populateWith.colCa;
      this.noticeBoard.colCa = actualCollabCategory;
      this.imagesPreview = this.noticeBoard?.link
        ? [this.noticeBoard?.link]
        : [];
    }
  },
  methods: {
    formattedDate(timestamp) {
      return timestamp ? this.convertTimestampToBrTime(timestamp) : '';
    },
    close() {
      this.$emit('close-dialog');
    },
    convertTimestampToBrTime(time) {
      return dayjs(time).format('DD/MM/YYYY');
    },
    compareAndConvertTimeBrToUsTime(time) {
      if (time.match('([0-9]{2})/([0-9]{2})/([0-9]{4})')) {
        return dayjs(time, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      return time;
    },
    updateInitialDate(date) {
      this.noticeBoard.initialDate = date;
    },
    updateFinalDate(date) {
      this.noticeBoard.finalDate = date;
    },
    onFilesSelected(file) {
      if (!file) return;
      this.dialogCropImage = true;
      this.imagesPreview = [URL.createObjectURL(file)];
    },
    onDeleteImage(index) {
      this.imagesPreview.splice(index, 1);
      if (this.imagesUpload) {
        this.imagesUpload.splice(index, 0);
      }

      this.noticeBoard = { ...this.noticeBoard, link: null };
    },
    clearForm() {
      this.$refs.form.reset();
      this.noticeBoard.active = false;
      this.noticeBoard.title = null;
      this.noticeBoard.subTitle = null;
      this.noticeBoard.text = null;
      this.noticeBoard.link = null;
      this.noticeBoard.initialDate = null;
      this.noticeBoard.finalDate = null;
      this.noticeBoard.colCa = [];
      this.attcUpload = [];
      this.imagesPreview = [];
      this.imageFormatted = '';
      this.$refs.form.resetValidation();
    },

    async checkImagesUploadAndCreatePhoto() {
      if (this.imagesUpload) {
        const imageType = imageFormater.getMimeType(this.imagesPreview);
        const formattedTitle = sanitize(this.noticeBoard.title);
        const imageFormatted = imageFormater.convertImageToFile(
          this.imagesPreview,
          `${formattedTitle.substring(0, 10)}.${imageType}`,
        );

        const photo = await createNoticeBoardPhoto(imageFormatted);
        this.noticeBoard = { ...this.noticeBoard, link: photo.link };
      }
    },
    async checkFileUpload() {
      const uploadPromises = this.attcUpload.map(async (item) => image(item));
      const uploadResponse = await Promise.all(uploadPromises);
      const arrayOfLinks = uploadResponse.map((item) => item[0]);
      this.noticeBoard = {
        ...this.noticeBoard,
        attc: arrayOfLinks,
      };
    },

    cropImageOnConfirm() {
      const { coordinates, canvas } = this.$refs.image.$refs.cropper.getResult();
      this.coordinates = coordinates;
      const imageToArray = canvas.toDataURL();
      this.imagesPreview = [imageToArray];
      this.dialogCropImage = false;
    },

    cancelCropImage() {
      this.dialogCropImage = false;
      this.imagesUpload = null;
      this.imagesPreview = [];
    },

    async updateNoticeBoard() {
      try {
        this.loading = true;
        await this.checkImagesUploadAndCreatePhoto();
        await this.checkFileUpload();
        await NoticeBoard.updateNoticeBoard({
          ...this.noticeBoard,
          initialDate: this.compareAndConvertTimeBrToUsTime(
            this.noticeBoard.initialDate,
          ),
          finalDate: this.compareAndConvertTimeBrToUsTime(
            this.noticeBoard.finalDate,
          ),
        });
        this.$emit('update-notice-board-list');
        this.clearForm();
        this.$toast.success('Aviso editado com sucesso');
        this.loading = false;
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
        this.loading = false;
      }
    },

    async submit() {
      await this.checkFileUpload();
      console.log('Terminou o upload....');
      if (!this.$refs.form.validate()) {
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }

      if (this.populateWith.id) {
        await this.updateNoticeBoard();
        return;
      }

      try {
        this.loading = true;
        await this.checkImagesUploadAndCreatePhoto();
        await this.checkFileUpload();
        if (this.noticeBoard.text === '') {
          this.$toast.error('O campo Texto precisa ser preenchido');
          this.loading = false;
          return;
        }
        await NoticeBoard.createNoticeBoard({
          ...this.noticeBoard,
          initialDate: this.compareAndConvertTimeBrToUsTime(
            this.noticeBoard.initialDate,
          ),
          finalDate: this.compareAndConvertTimeBrToUsTime(
            this.noticeBoard.finalDate,
          ),
        });
        this.$emit('update-notice-board-list');
        this.clearForm();
        this.$toast.success('Aviso cadastrado com sucesso');
        this.loading = false;
      } catch (e) {
        this.$handleHttpError(e);
        this.loading = false;
      }
    },
    async loadCollaboratorCategories() {
      this.collaboratorCategory = await CollaboratorCategoryService.findAll();
    },
  },
};
</script>
