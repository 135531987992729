import api from '@/services/api';

const headers = {
  'Content-Type': 'multipart/form-data',
};

/**
 * Service to save photo with custom resolution 1024x768
 */

/* eslint-disable-next-line import/prefer-default-export */
export const createNoticeBoardPhoto = (file) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  // eslint-disable-next-line no-restricted-syntax
  return api.post('notice-board/upload-img', formData, headers);
};
